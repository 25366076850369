import CustomModal from 'components/global/CustomModal'
import React from 'react'
import { creationEventStore } from 'state/event/eventCreationStore'
import { eventTypesList } from './constants'
import { EventTypeListing } from 'types/event/events'
import { infoMessage } from 'api/global'
import { useHistory } from 'react-router-dom'
import { leagueEventStore } from 'state/event/leagueEvent'

type EventType = 'event' | 'community' | 'external' | 'fundraiser' | 'league'

const EventTypeModal: React.FC = () => {
  const history = useHistory()
  const {
    eventTypeModalVisible,
    setEventTypeModalVisible,
    setEventData,
    setStepCounter,
  } = creationEventStore(store => ({
    eventTypeModalVisible: store.eventTypeModalVisible,
    setEventTypeModalVisible: store.setEventTypeModalVisible,
    setEventData: store.setEventData,
    setStepCounter: store.setStepCounter,
  }))
  const { setLeagueData, setLeagueCounter } = leagueEventStore(store => ({
    setLeagueData: store.setLeagueData,
    setLeagueCounter: store.setLeagueStepCounter,
  }))

  const hanldeTypeClick = (type: EventType) => () => {
    switch (type) {
      case 'event':
        setEventTypeModalVisible(false)
        setEventData(null)
        setStepCounter(0)
        history.push('/events/create-event')
        break
      case 'community':
        infoMessage('It will be coming soon')
        break
      case 'external':
        infoMessage('It will be coming soon')
        break
      case 'fundraiser':
        infoMessage('It will be coming soon')
        break
      case 'league':
        setLeagueData(null)
        setLeagueCounter(0)
        setEventTypeModalVisible(false)
        history.push('/events/create-league')
        break
      default:
        infoMessage('Event type is not in the listing')
    }
  }

  return (
    <>
      <CustomModal
        openModal={eventTypeModalVisible}
        closeModal={setEventTypeModalVisible}
        title={'What would you like to create?'}
        footer={false}
      >
        <div className="flex flex-wrap gap-20px justify-center">
          {eventTypesList.map((type: EventTypeListing) => {
            return (
              <div
                key={type.id}
                className="flex align-center justify-center flex-col border-1px radius-12 px-20px py-20px gap-20px pointer"
                style={{ width: '150px' }}
                onClick={hanldeTypeClick(type.title.toLowerCase() as EventType)}
              >
                <img src={type.icon} alt={type.title} />
                <h3 className="text-16 w-600">{type.title}</h3>
              </div>
            )
          })}
        </div>
      </CustomModal>
    </>
  )
}

export default EventTypeModal
