import { ArrowDown, CaretDown } from '@phosphor-icons/react'
import ChatMainInfo from 'components/global/chat/ChatMainInfo'
import React from 'react'

const AllMessages: React.FC = () => {
  const messages = ['']

  return (
    <div className="scroller overflow-auto-y py-20px">
      <div className="flex align-center gap-20px px-15px">
        <button className="flex align-center justify-center border-1px gap-10px px-10px py-7px radius-full bg-transparent">
          <ArrowDown size={20} color="#c5c7c9" />
          <span className="text-14 w-500">More</span>
          <CaretDown size={20} color="#000" />
        </button>
        <button className="flex align-center justify-center border-1px gap-10px px-10px py-7px radius-full bg-transparent">
          <span className="text-14 w-500">All messages</span>
          <CaretDown size={20} color="#000" />
        </button>
      </div>

      <div className="mt-20px">
        <h3 className="text-16 w-600 px-20px">All messages</h3>
        {!messages.length ? (
          <p className="px-20px text-16 w-400 text-gray">No messages</p>
        ) : (
          <div className="mt-20px">
            <ChatMainInfo
              avatar=""
              name="Mai nas"
              createdBy="Admin"
              lastMessage="I’ll meet them"
              time="04:12 PM"
              active={true}
            />
            <ChatMainInfo
              avatar=""
              name="Mai nas"
              createdBy="Admin"
              lastMessage="I’ll meet them"
              time="04:12 PM"
              active={false}
            />
            <ChatMainInfo
              avatar=""
              name="Mai nas"
              createdBy="Admin"
              lastMessage="I’ll meet them"
              time="04:12 PM"
              active={false}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default AllMessages
