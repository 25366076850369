import React, { useState } from 'react'
import { Col, Form, Input, Radio, RadioChangeEvent, Row } from 'antd'
import RecepientInput from './RecepientInput'
import { EventResp } from 'types/event/events'

interface ChatContentProps {
  eventData: EventResp
}

const ChatContent: React.FC<ChatContentProps> = ({ eventData }) => {
  const [form] = Form.useForm()
  const [contactType, setContactType] = useState<'chat' | 'email' | 'sms'>(
    'chat',
  )

  const formSchema = {}
  const onFinish = (values: any) => {
    console.log(values)
  }

  const handleChange = (e: RadioChangeEvent) => {
    setContactType(e.target.value)
  }

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        initialValues={formSchema}
        onFinish={onFinish}
      >
        <Row gutter={10}>
          <Col className="mt-10">
            <label className="input-label">Contact via</label>
            <Form.Item
              className="form-control mb-0"
              name="contactVia"
              rules={[
                { required: true, message: 'Contact method is required' },
              ]}
            >
              <Radio.Group defaultValue={'chat'} onChange={handleChange}>
                <Radio value={'email'} className="mr-40 radio">
                  Email
                </Radio>
                <Radio value={'sms'} className="mr-40 radio">
                  SMS
                </Radio>
                <Radio value={'chat'} className="mr-40 radio">
                  Chat
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <RecepientInput eventData={eventData} />
        <Row className="mt-10px">
          <Col>
            {contactType === 'email' && (
              <>
                <label className="input-label">Subject</label>
                <Form.Item className="form-control mb-0" name="subject">
                  <Input
                    placeholder="Email subject"
                    name="subject"
                    className="radius-12px"
                  />
                </Form.Item>
              </>
            )}
            <Form.Item className="form-control mb-0 mt-15px" name="message">
              <Input.TextArea
                className="note-field"
                placeholder="Your message..."
                autoSize={{ minRows: 5, maxRows: 10 }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

export default ChatContent
