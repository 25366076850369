import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { axiosDelete, axiosGet, axiosPatch, axiosPost } from 'Redux/axiosHelper'
import {
  CreateDivisionPayload,
  DivisonListingResp,
} from 'types/league/division'

// get all devisions of one league
const getLeagueDivisions = async (leagueId: string) => {
  const response = await axiosGet(`v1/league-division/step-3/${leagueId}`)
  return response.data
}

export const useGetLeagueDivisions = (leagueId: string) => {
  const leagueDivisions = useQuery<DivisonListingResp[]>(
    ['get-league-divisions'],
    () => (leagueId ? getLeagueDivisions(leagueId) : null),
    {
      enabled: false,
    },
  )
  return leagueDivisions
}

// create new divisiion
const createDivision = async ({
  leagueId,
  payload,
}: {
  leagueId: string
  payload: CreateDivisionPayload
}) => {
  const response = await axiosPost(
    payload,
    `v1/league-division/step-3/${leagueId}`,
  )
  return response.data
}

export const useCreateDivision = () => {
  const queryClient = useQueryClient()
  return useMutation(createDivision, {
    onSuccess: newDivsion => {
      queryClient.setQueryData(
        ['get-league-divisions'],
        (oldData: DivisonListingResp[] | undefined) => {
          return oldData ? [...oldData, newDivsion] : [newDivsion]
        },
      )
    },
  })
}

// update division by id
const updateDivision = async ({
  divisionId,
  payload,
}: {
  divisionId: string
  payload: CreateDivisionPayload
}) => {
  const response = await axiosPatch(
    payload,
    `v1/league-division/step-3/${divisionId}`,
  )
  return response.data
}
export const useUpdateDivsion = () => {
  const queryClient = useQueryClient()
  return useMutation(updateDivision, {
    onSuccess: updatedData => {
      queryClient.setQueryData(
        ['get-league-divisions'],
        (oldData: DivisonListingResp[] | undefined) => {
          if (!oldData) return [updatedData]
          return oldData.map(division =>
            division._id === updatedData._id ? updatedData : division,
          )
        },
      )
    },
  })
}

// delete division by id
const deleteDivision = async (divisionId: string) => {
  const response = await axiosDelete(
    `v1/league-division/step-3/delete-by-id/${divisionId}`,
  )
  return response.data
}
export const useDeleteDivision = () => {
  const queryClient = useQueryClient()
  return useMutation(deleteDivision, {
    onSuccess: deletedData => {
      queryClient.setQueryData(
        ['get-league-divisions'],
        (oldData: DivisonListingResp[] | undefined) => {
          if (!oldData) return []
          return oldData.filter(division => division._id !== deletedData._id)
        },
      )
    },
  })
}
