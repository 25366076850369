import React, { useEffect } from 'react'
import { Col, Form, FormInstance, Row, Select } from 'antd'
import AgeGroup from './InputComps/AgeGroup'
import GenderSelect from 'helper/GenderSelect'
import { leagueEventStore } from 'state/event/leagueEvent'
import {
  useCreateDivision,
  useUpdateDivsion,
} from 'api/LeagueCreation/leagueDivision'
import { CreateDivisionPayload } from 'types/league/division'
import { myStore } from 'state/store'
import { infoMessage } from 'api/global'

interface DivisionFormProps {
  form: FormInstance
}

const DivisionForm: React.FC<DivisionFormProps> = ({ form }) => {
  const {
    leagueData,
    setAddDevisonModal,
    divisionData,
    setIsUpdatingDivision,
    setDivisionData,
    setLeagueData,
  } = leagueEventStore(store => ({
    leagueData: store.leagueData,
    setAddDevisonModal: store.setAddDevisonModal,
    divisionData: store.divisionData,
    setIsUpdatingDivision: store.setIsUpdatingDivision,
    setDivisionData: store.setDivisionData,
    setLeagueData: store.setLeagueData,
  }))
  const setModalBtnLoader = myStore(store => store.setModalBtnLoader)
  const {
    mutate: createDivisionMutation,
    isLoading: creaetDivisionMutationLoading,
  } = useCreateDivision()
  const { mutate: updateDivisionMutation, isLoading: updateDivisionLoading } =
    useUpdateDivsion()
  const skillLevels = [
    {
      key: 'low',
      name: 'Low',
    },
    {
      key: 'medium',
      name: 'Medium',
    },
    {
      key: 'high',
      name: 'High',
    },
  ]

  const formSchema = {
    ageGroup: divisionData ? divisionData.ageGroup : undefined,
    gender: divisionData ? divisionData.gender : undefined,
    skillLevel: divisionData ? divisionData.skillLevel : undefined,
  }

  const onFinish = (values: CreateDivisionPayload) => {
    if (!leagueData._id)
      return infoMessage('League is not created! Something went wrong')
    const payload: CreateDivisionPayload = { ...values }
    payload.organizationId = leagueData.organizationId

    if (divisionData) {
      updateDivisionMutation(
        { divisionId: divisionData._id, payload },
        {
          onSuccess: () => {
            setIsUpdatingDivision(false)
            setDivisionData(null)
            setAddDevisonModal(false)
          },
        },
      )
    } else {
      createDivisionMutation(
        { leagueId: leagueData._id, payload },
        {
          onSuccess: () => {
            setLeagueData({
              ...leagueData,
              statuses: { ...leagueData.statuses, divisions: true },
            })
            setAddDevisonModal(false)
          },
        },
      )
    }
  }

  useEffect(() => {
    setModalBtnLoader(creaetDivisionMutationLoading || updateDivisionLoading)
  }, [creaetDivisionMutationLoading || updateDivisionLoading])

  return (
    <>
      <Form
        form={form}
        layout="vertical"
        initialValues={formSchema}
        onFinish={onFinish}
      >
        <Row>
          <Col span={24}>
            <label className="input-label required-input">Age group</label>
            <Form.Item
              name="ageGroup"
              className="form-control allergies-field "
              rules={[{ required: true, message: 'Age group is required' }]}
            >
              <AgeGroup
                data={divisionData ? divisionData.ageGroup : undefined}
                form={form}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label required-input">Gender</label>
            <Form.Item
              name="gender"
              rules={[{ required: true, message: 'Gender is required' }]}
            >
              <GenderSelect
                form={form}
                data={divisionData ? divisionData.gender : undefined}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24} className="mt-10px">
            <label className="input-label required-input">Skill level</label>
            <Form.Item
              name="skillLevel"
              rules={[{ required: true, message: 'skill is required' }]}
            >
              <Select className="h-50px select-field" placeholder={'Select'}>
                {skillLevels.map((skill: { key: string; name: string }) => (
                  <Select.Option key={skill.key} value={skill.key}>
                    {skill.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default DivisionForm
