import React from 'react'
import { Avatar } from 'antd'
import { getNameSignature, turncateText } from 'helper/utility'
import '../styles/styles.scss'

interface ChatMainInfoProps {
  avatar: string | undefined
  name: string
  createdBy: string
  lastMessage: string
  time: string
  active: boolean
}

const ChatMainInfo: React.FC<ChatMainInfoProps> = ({
  avatar,
  name,
  createdBy,
  lastMessage,
  time,
  active,
}) => {
  return (
    <div
      className={`pointer py-15px px-20px flex justify-between mt-5px border-btm ${
        active && 'bg-colored'
      }`}
    >
      <div className="flex gap-10px">
        <Avatar size={45} src={avatar}>
          {getNameSignature(name)}
        </Avatar>
        <div>
          <h2 className="text-16 w-500">{name}</h2>
          <p className="text-14 w-400 text-gray">{createdBy}</p>
          <p className="text-14 w-400">{turncateText(lastMessage, 25)}</p>
        </div>
      </div>
      <p>{time}</p>
    </div>
  )
}

export default ChatMainInfo
