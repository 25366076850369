import { FormInstance, Select } from 'antd'
import { DefaultOptionType } from 'antd/lib/select'
import { useGetAgeGroups } from 'api/Lists'
import Spinner from 'components/Spinner'
import React, { useEffect } from 'react'
import { AgeGroups } from 'types/globalTypes'

interface AgeGroupProps {
  data: any
  form: FormInstance
}

const AgeGroup: React.FC<AgeGroupProps> = ({ data, form }) => {
  const {
    data: ageGroupsData,
    isLoading: ageGroupsLoading,
    isFetching: ageGroupsFetching,
    refetch: refetchAgeGroups,
  } = useGetAgeGroups()

  useEffect(() => {
    refetchAgeGroups()
  }, [])

  return (
    <div>
      <Select
        placeholder={'Select Age'}
        defaultValue={data && data.name}
        onChange={(_, item: DefaultOptionType) => {
          form.setFieldsValue({
            ageGroup: {
              _id: item.key,
              name: item.value,
            },
          })
        }}
        className="h-50px select-field"
      >
        {ageGroupsLoading || ageGroupsFetching ? (
          <div className="py-20px flex align-center justify-center">
            <Spinner />
          </div>
        ) : (
          <>
            {ageGroupsData.map((age: AgeGroups) => (
              <Select.Option key={age._id} value={age.name}>
                {age?.name}
              </Select.Option>
            ))}
          </>
        )}
      </Select>
    </div>
  )
}

export default AgeGroup
