import React, { useState } from 'react'
import AllMessages from './Sections/AllMessages'
import MessageContainer from './Sections/MessageContainer'
import './styles.scss'
import RecepientBox from 'components/global/chat/RecepientBox'

const MainChat: React.FC = () => {
  const [showReceipientContainer, setShowReceipientContainer] = useState(true)
  return (
    <div className="flex">
      <div className="all-chats-name">
        <AllMessages />
      </div>
      <div
        className={`message-container px-20px ${
          showReceipientContainer ? 'recipient' : 'recipient-hidden'
        }`}
      >
        <MessageContainer
          showReciepientContainer={showReceipientContainer}
          setShowReceipientContainer={setShowReceipientContainer}
        />
      </div>
      <div
        className={`recepient-container ${
          showReceipientContainer ? '' : 'hidden'
        }`}
      >
        <RecepientBox avatar="" name="Amin Martinez" role="mentor" />
      </div>
    </div>
  )
}

export default MainChat
