import { create } from 'zustand'

const eventChatCreationStore = (set: any) => ({
  chatModalVisible: false,
  setChatModalVisible: (state: boolean) =>
    set(() => ({ chatModalVisible: state })),

  selectedParticipants: [],
  setSelectedParticipants: (state: string[]) =>
    set(() => ({
      selectedParticipants: state,
    })),
})

export const eventShatStore = create(eventChatCreationStore)
