import IconsTextNavigation from 'components/global/IconsTextNavigation'
import React from 'react'

const ItemsToBring: React.FC = () => {
  return (
    <>
      <div>
        <IconsTextNavigation
          iconName="ShoppingBagOpen"
          heading="Items to bring"
        />
      </div>
    </>
  )
}

export default ItemsToBring
