import React, { useEffect } from 'react'
import { FormInstance, Select } from 'antd'
import Spinner from 'components/Spinner'
import { capitalizeFirstLetter } from 'helper/utility'
import { leagueEventStore } from 'state/event/leagueEvent'
import { useGetLeagueDivisions } from 'api/LeagueCreation/leagueDivision'
import { DivisonListingResp } from 'types/league/division'

interface DivisionInputProps {
  form: FormInstance
  data: string
}

const DivisionInput: React.FC<DivisionInputProps> = ({ form, data }) => {
  const { leagueData } = leagueEventStore(store => ({
    leagueData: store.leagueData,
  }))

  const {
    data: divisionData,
    isLoading: divisionsLoading,
    isFetching: divisionFetching,
    refetch: refetchDivisions,
  } = useGetLeagueDivisions(leagueData._id)

  useEffect(() => {
    refetchDivisions()
  }, [])

  return (
    <div>
      <Select
        className="h-50px select-field"
        placeholder="Select division"
        defaultValue={data}
        onChange={(_, item: { key: string; value: string }) => {
          form.setFieldsValue({
            divisionId: item.value,
          })
        }}
      >
        {divisionsLoading || divisionFetching ? (
          <div className="py-20px flex justify-center">
            <Spinner />
          </div>
        ) : (
          divisionData.map((division: DivisonListingResp) => (
            <Select.Option key={division._id} value={division._id}>
              <div
                className="flex align-center justify-between gap-20px mt-5px"
                key={division._id}
              >
                <div>
                  <h3 className="text-16 w-600">
                    {capitalizeFirstLetter(division.gender)}{' '}
                    <span className="text-gray text-14 w-400 inline ml-10px">{`${division.ageGroup.name} . ${division.skillLevel}`}</span>
                  </h3>
                </div>
              </div>
            </Select.Option>
          ))
        )}
      </Select>
    </div>
  )
}

export default DivisionInput
