import React, { useState, useRef, useEffect } from 'react'
import { Input } from 'antd'
import { Paperclip, Smiley, PaperPlaneTilt } from '@phosphor-icons/react'
import Picker from 'emoji-picker-react'
import '../styles/styles.scss'

const SendInput: React.FC = () => {
  const [imageUrls] = useState<string>()
  const [sendFile, setSendFile] = useState<boolean>(false)
  const [messageType] = useState<string>('text')
  const [thumbnailUrl] = useState<string>('')
  const [inputStr, setInputStr] = useState<string>('')
  const [showPicker, setShowPicker] = useState<boolean>(false)
  const [imageName] = useState<string>()
  const emojiPickerRef = useRef(null)

  const onEmojiClick = (event, emojiObject) => {
    setInputStr(prevInput => prevInput + event.emoji)
    // setShowPicker(false);
  }

  const togglePicker = event => {
    event.stopPropagation()
    setShowPicker(val => !val)
  }

  useEffect(() => {
    const closeEmojiPicker = event => {
      if (
        showPicker &&
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowPicker(false)
      }
    }

    document.addEventListener('click', closeEmojiPicker)

    return () => {
      document.removeEventListener('click', closeEmojiPicker)
    }
  }, [showPicker])

  const sendMessage = () => {
    console.log('send message')
  }

  return (
    <div>
      <form action="" onSubmit={sendMessage}>
        <Input
          type="text"
          placeholder="Write a message..."
          className={`input-box`}
          value={inputStr}
          onChange={e => setInputStr(e.target.value)}
        />

        {imageName && (
          <div>
            <h3 className="text-12 w-400">{imageName}</h3>
            <div
              className={`${
                messageType === 'Video' || messageType === 'Image'
                  ? 'd-block'
                  : 'd-hidden'
              }`}
            >
              <img
                src={messageType === 'Video' ? thumbnailUrl : imageUrls}
                alt=""
                width={80}
                height={80}
              />
            </div>
            {messageType.toLowerCase() === 'voicenote' && (
              <audio controls>
                <source src={imageUrls} type="audio/mpeg" />
                Your browser does not support the audio element.
              </audio>
            )}
          </div>
        )}
        <div className="flex justify-between align-center mt-20px">
          <div className="icons flex align-center gap-16px">
            <Paperclip
              size={24}
              height={24}
              color="#000000"
              onClick={() => setSendFile(!sendFile)}
            />
            <Smiley
              size={24}
              height={24}
              color="#000000"
              onClick={event => togglePicker(event)}
            />
          </div>
          <div
            ref={emojiPickerRef}
            className="emoji"
            style={{ position: 'absolute', bottom: '7rem', zIndex: '99999' }}
          >
            {showPicker && (
              <Picker onEmojiClick={onEmojiClick} searchDisabled={true} />
            )}
          </div>
          <button className={`send`} type="submit">
            <PaperPlaneTilt
              size={24}
              height={24}
              color={inputStr.length > 0 || imageUrls ? '#2BA7CA' : '#939598'}
              weight="fill"
            />
          </button>
        </div>
        {/* {sendFile && (
          <Uploadfile
            isModalOpen={sendFile}
            setIsOpenModal={setSendFile}
            setImgLink={setImageUrls}
            setType={setType}
            setThumbnailUrl={setThumbnailUrl}
            setImageName={setImageName}
          />
        )} */}
      </form>
    </div>
  )
}

export default SendInput
