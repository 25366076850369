import React from 'react'
import CustomModal from 'components/global/CustomModal'
import { eventShatStore } from 'state/event/eventChat'
import CustomModalFooter from 'components/global/CustomModalFooter'
import ChatContent from './ModalContnet/ChatContent'
import { EventResp } from 'types/event/events'

interface ChatModalProps {
  eventData: EventResp
}

const ChatModal: React.FC<ChatModalProps> = ({ eventData }) => {
  const { chatModalVisible, setChatModalVisible } = eventShatStore(store => ({
    chatModalVisible: store.chatModalVisible,
    setChatModalVisible: store.setChatModalVisible,
  }))

  const onSubmit = () => {}

  return (
    <>
      <CustomModal
        openModal={chatModalVisible}
        closeModal={setChatModalVisible}
        title="Contact attendees"
        footer={
          <CustomModalFooter
            multibutton={true}
            btnAlignment="justify-end"
            btnText="Cancel,Send"
            onSubmit={onSubmit}
          />
        }
      >
        <ChatContent eventData={eventData} />
      </CustomModal>
    </>
  )
}

export default ChatModal
