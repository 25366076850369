import React, { useEffect, useState } from 'react'
import { MagnifyingGlass, PlusCircle, Spinner } from '@phosphor-icons/react'
import { Col, Input, Row, Tabs } from 'antd'
import CustomSelectMenu from 'components/global/CustomSelectMenu'
import Attendees from './Attendees'
import { EventResp } from 'types/event/events'
import { useGetEventParticipants } from 'api/Events'
import { failureMessage } from 'api/global'
import { Person } from 'types/globalTypes'

interface RecepientInputProps {
  eventData: EventResp
}

const RecepientInput: React.FC<RecepientInputProps> = ({ eventData }) => {
  const { TabPane } = Tabs
  const [open, setOpen] = useState<boolean>(false)
  const [activeKeyTab, setActiveKeyTab] = useState<string>('1')
  const [participants, setParticipants] = useState<Person[]>([])

  const onChange = (key: string) => {
    setActiveKeyTab(key)
  }

  const {
    data: eventParticipantsData,
    isLoading: isEventParticipantsLoading,
    isFetching: isEventParticipantsFetching,
    refetch: refetchEventParticipants,
  } = useGetEventParticipants(eventData._id)

  const menuItems = [
    {
      key: 'search',
      label: (
        <>
          <div className="flex align-center gap-10px border-btm">
            <MagnifyingGlass size={20} color="#000" />
            <input type="text" className="w-full border-none" />
          </div>
          <div className="mt-10px">
            <Tabs defaultActiveKey={activeKeyTab} onChange={onChange}>
              <TabPane tab="Attendees" key="1">
                <>
                  {isEventParticipantsLoading || isEventParticipantsFetching ? (
                    <div className="py-40px">
                      <Spinner />
                    </div>
                  ) : (
                    <Attendees participants={participants} />
                  )}
                </>
              </TabPane>
              <TabPane tab="Guardians" key="2">
                <Attendees participants={participants} />
              </TabPane>
              <TabPane tab="Mentors" key="3">
                <Attendees participants={participants} />
              </TabPane>
            </Tabs>
          </div>
        </>
      ),
    },
  ]

  useEffect(() => {
    if (!eventData) {
      return failureMessage('Event not found')
    }
    refetchEventParticipants()
  }, [eventData])

  useEffect(() => {
    if (eventParticipantsData) {
      setParticipants([
        ...eventParticipantsData.kids,
        ...eventParticipantsData.guardians,
        ...eventParticipantsData.authorizedAdults,
      ])
    }
  }, [eventParticipantsData])

  console.log(participants, 'event Participants')

  return (
    <Row className="mt-10px">
      <Col>
        <label className="input-label">Recipients</label>
        <CustomSelectMenu open={open} setOpen={setOpen} menuItems={menuItems}>
          <div
            className="w-full flex align-center justify-between gap-20px mt-5px border-1px radius-12 px-15px h-50px"
            onClick={() => setOpen(true)}
          >
            <div>
              <span className="text-14 text-gray">Select recipients</span>
            </div>
            <PlusCircle size={20} color="#000" />
          </div>
        </CustomSelectMenu>
      </Col>
    </Row>
  )
}

export default RecepientInput
